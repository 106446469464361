import zxcvbn from 'zxcvbn';

const meter = document.getElementById('meter');
const password = document.getElementById('user_password');
const formGroup = document.getElementById('fg-password');
const helpText = document.getElementById('weak-password');

password.addEventListener('input', () => {
  const { score } = zxcvbn(password.value);

  for (let i = 1; i <= 4; i += 1) {
    if (i === score) {
      meter.classList.add(`score-${i}`);
    } else {
      meter.classList.remove(`score-${i}`);
    }
  }

  if (score < 3) {
    formGroup.classList.add('has-warning');
    helpText.classList.remove('hidden');
  } else {
    formGroup.classList.remove('has-warning');
    helpText.classList.add('hidden');
  }
});
